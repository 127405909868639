<template>
    <div class="container page catalogues">
        <loading-spinner
            v-if="loading && !existData"
            class="fixed"
        />
        <b-row
            v-if="existData"
            class="mtd-40"
        >
            <b-col
                lg="6"
                sm="12"
                class="description"
            >
                <h4>{{ $t('header.navbar.catalogs') }}</h4>
                <div
                    v-dompurify-html="page.description"
                    class="content mt-4"
                />
            </b-col>
            <b-col
                lg="6"
                sm="12"
                class="images mt-mb"
            >
                <div>
                    <img
                        :src="images.big_image"
                        alt=""
                    >
                    <div class="small">
                        <img
                            :src="images.small_image"
                            alt=""
                        >
                    </div>
                </div>
            </b-col>
        </b-row>
        <b-row
            v-if="existData && existCatalogues"
            class="mt-40"
        >
            <h6>{{ $t('page.catalogue.listingText') }}</h6>
            <div class="items d-flex">
                <div
                    v-for="catalogue in catalogues.elements"
                    :key="`catalogue-${catalogues.elements.indexOf(catalogue)}`"
                    class="item"
                >
                    <div class="image">
                        <img
                            :src="catalogue.thumbnail"
                            :alt="catalogue.title"
                        >
                    </div>
                    <div class="background-layer" />
                    <div class="action">
                        <a
                            :href="catalogue.link"
                            target="_blank"
                            rel="noopener noreferrer"
                        >
                            <img
                                src="/images/fe_download.svg"
                                :alt="$t('page.catalogue.downloadText')"
                            >
                            <p class="sm-sbold">{{ $t('page.catalogue.downloadText') }}</p>
                        </a>
                    </div>
                </div>
            </div>
            <b-col
                v-if="catalogues.page.last_page > 1"
                cols="12"
            >
                <listing-pagination
                    :page="catalogues.page"
                    type="catalogues"
                />
            </b-col>
        </b-row>
    </div>
</template>
<script>
    import { i18n } from 'vue-lang-router';
    import { mapActions, mapState } from 'vuex';

    import LoadingSpinner from '../components/loaders/LoadingSpinner';
    import ListingPagination from './category/ListingPagination';
    export default {
        components: { ListingPagination, LoadingSpinner },
        name: 'Catalogue',
        data() {
            return {
                acceptedSlugs: ['cataloage', 'katalogusok'],
            };
        },
        computed: {
            ...mapState('page', ['catalogues', 'page', 'images', 'loading']),
            lang() {
                return i18n.locale;
            },
            existData() {
                if (
                    this.loading &&
                    this.existCataloguePageData &&
                    this.acceptedSlugs.includes(this.page.slug)
                ) {
                    return true;
                } else if (!this.loading && this.existCataloguePageData) {
                    return true;
                }
                return false;
            },
            existCataloguePageData() {
                return (
                    Object.keys(this.page).length > 0 && Object.keys(this.images).length > 0
                );
            },
            existCatalogues() {
                return (
                    Object.keys(this.catalogues).length > 0 &&
                    this.catalogues.elements.length > 0
                );
            },
        },
        methods: {
            ...mapActions('page', ['getCatalogues']),
            async initialize() {
                let query = {};
                if (Object.prototype.hasOwnProperty.call(this.$route.query, 'page')) {
                    query = { page: this.$route.query.page };
                }
                Object.assign(query, { slug: this.$t('page.catalogue.slug') });
                await this.getCatalogues(query);
            },
        },
        created() {
            this.initialize();
        },
        watch: {
            lang: {
                handler() {
                    this.initialize();
                },
                deep: true,
            },
        },
        metaInfo() {
            return {
                title: this.page.meta_title,
                meta: [
                    {
                        vmid: 'description',
                        name: 'description',
                        content: this.page.meta_description,
                    },
                    {
                        vmid: 'keywords',
                        name: 'keywords',
                        content: this.page.meta_keywords,
                    },
                    {
                        vmid: 'og:title',
                        property: 'og:title',
                        content: this.page.meta_title,
                        template: (chunk) => `${chunk} | Pescario.ro`,
                    },
                    {
                        vmid: 'og:url',
                        property: 'og:url',
                        content: window.location.origin + this.$route.fullPath,
                    },
                    {
                        vmid: 'og:description',
                        property: 'og:description',
                        content: this.page.meta_description,
                    },
                    {
                        vmid: 'og:image',
                        property: 'og:image',
                        content: this.page.image,
                    },
                ],
                link: [
                    {
                        rel: 'canonical',
                        href: window.location.origin + this.$route.fullPath,
                    },
                ],
            };
        },
    };
</script>
<style scoped lang="scss">
.catalogues {
  min-height: calc(100vh - 72px - 392px);
  > .row {
    margin-bottom: 75px;
    @include media-breakpoint-down(lg) {
      margin-bottom: 24px;
    }
  }
  .description {
    padding-right: 60px;
  }
  .images {
    @include media-breakpoint-down(lg) {
      margin: 24px 0px;
    }
    > div {
      position: relative;
      @include media-breakpoint-down(lg) {
        text-align: right;
      }
      img {
        max-width: 100%;
        border-radius: 8px;
        @include media-breakpoint-down(lg) {
          width: 80%;
        }
      }
      .small {
        border-radius: 8px;
        background-color: $white;
        padding: 12px;
        position: absolute;
        bottom: -74px;
        left: -74px;
        max-height: 180px;
        aspect-ratio: 3 / 2;
        @include media-breakpoint-between(md, lg) {
          width: 400px;
          bottom: -50px;
          left: -12px;
          max-height: 250px;
        }
        @include media-breakpoint-between(sm, md) {
          width: 300px;
          bottom: -60px;
          left: -12px;
          max-height: 250px;
        }
        @include media-breakpoint-down(sm) {
          width: 60%;
          bottom: -60px;
          left: -12px;
          max-height: 250px;
        }
        img {
          object-fit: cover;
          @include media-breakpoint-down(lg) {
            width: 100%;
          }
        }
      }
    }
  }
  h6 {
    color: $black;
    margin: 24px 0px;
  }
  .items {
    flex-wrap: wrap;
    gap: 24px;
    justify-content: flex-start;
    @include media-breakpoint-down(sm) {
      gap: 12px;
    }
    .item {
      background-color: $white;
      width: calc(20% - 96px / 5);
      max-width: 267px;
      position: relative;
      border-radius: 8px;
      overflow: hidden;
      @include media-breakpoint-between(md, lg) {
        width: calc(25% - 72px / 4);
      }
      @include media-breakpoint-between(sm, md) {
        width: calc(100% / 3 - 48px / 3);
      }
      @include media-breakpoint-down(sm) {
        width: calc(50% - 6px);
      }
      .image {
        aspect-ratio: 3 / 4;
        img {
          width: 100%;
          height: 100%;
          object-fit: cover;
        }
      }
      > p {
        color: $black;
        padding: 20px 20px 0px 20px;
        margin-bottom: 12px;
        text-align: center;
        display: -webkit-box;
        -webkit-line-clamp: 1;
        -webkit-box-orient: vertical;
        overflow: hidden;
        text-overflow: ellipsis;
        @include media-breakpoint-down(sm) {
          padding: 20px 10px 0px 10px;
          font-size: 10px;
        }
      }
      .background-layer {
        position: absolute;
        bottom: 0;
        width: 100%;
        height: 100%;
        text-align: center;
        opacity: 0;
        transition: opacity 250ms ease-out;
      }
      .action {
        position: absolute;
        bottom: 0;
        width: 100%;
        height: 100%;
        text-align: center;
        opacity: 0;
        transition: opacity 250ms ease-out;
        display: flex;
        justify-content: center;
        align-items: center;
        a {
          display: flex;
          justify-content: center;
          align-items: center;
          gap: 8px;
          width: 100%;
          background-color: $primary;
          img {
            height: 16px;
            width: 16px;
          }
          p {
            letter-spacing: 2px;
            font-size: 12px;
            color: $white;
            @include media-breakpoint-down(sm) {
              font-size: 10px;
              letter-spacing: 2px;
            }
          }
        }
      }
      &:hover {
        .background-layer {
          opacity: 0.5;
          background-color: $gray-thin;
        }
        .action {
          opacity: 1;
        }
      }
    }
  }
}
</style>
